import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Input } from "@fluentui/react-components";
import { getContext, clearCache } from "../api/authUtils.api";
import { SharePermissions } from "../api/Permissions.api";
import * as microsoftTeams from "@microsoft/teams-js";
import { getTokenForSSO } from "../api/authUtils.api";

const Tab: React.FC = () => {
  // Récupérez l'e-mail du paramètre d'URL
  const email = localStorage.getItem("userEmail");
  var emailDomain = email
  if (email)  {
    emailDomain = email.slice(email.indexOf("@"));
  }

  // Debugging share permissions button and input
  // const [inputValue, setInputValue] = useState("");
  // const handleClick = () => {
  //   if (inputValue) {
  //     SharePermissions(inputValue);
  //   }
  // };

  // useState no more use currently
  //  but instead use: window.location.href = url;
  // const [customUrl, setCustomUrl] = useState("");

  const openInTab = async () => {
    let tabConfig =  microsoftTeams.pages.getConfig() ;
    let url = (await tabConfig).websiteUrl // contentUrl
    
    console.log("openInTab()")
    var email = localStorage.getItem("userEmail");

    if (!email) {
      let token = await getTokenForSSO();
      if (!token) {
        console.log("Error while retrieving the token to get the users' email - will open the board without SSO")
      }    
      email = localStorage.getItem("userEmail");
    }

    var emailDomain = email
    if (email)  {
      emailDomain = email.slice(email.indexOf("@"));
    }

    if (!url) {
       console.log("websiteUrl not found in current tab config - no board will be open in tab");
       return ;
    }

    // Before each board opening, handle the user's permissions
    // Get the boardID from the url -> the last part after the "/"
    var boardId = url.substring(url.lastIndexOf('/') + 1);
    var permResutl = await SharePermissions(boardId)
    if (!permResutl) {
        return ; // SharePermissions failed, a log has been displayed
      }

    if (emailDomain) {
       url += `?MS_SSO=${emailDomain}`; // encodeURIComponent(emailDomain)
    }
   
    // Needed to have the board URL for the iFrame
    console.log("localStorage boardUrlSSO: " + url);
    localStorage.setItem("boardUrlSSO", url);

    // Open the url in the current iFrame
    window.location.href = url;

    window.open(url, "_blank");
   };

  useEffect(() => {
    const fetchData = async () => {
      console.log("fetchData()")

      // Ensure that the context information is correct/updated
      await clearCache() ; // Added for new code, was done in getIdToken()
      await getContext();

      // open the tab when clicked
      openInTab();

      /* const shareResult = await SharePermissions(
        localStorage.getItem("boardId") || ""
      );
      if (shareResult === false) {
        console.log("Error sharing permissions");
      } */
      return ;
    };

    fetchData();
  }, []); // Make sure to specify an empty dependency so that this happens only once

  // We don't need to return any JSX since we're navigating directly
  // See  window.location.href = url; here above
  return null;
};

export default Tab;
